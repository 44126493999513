.project__filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
  cursor: pointer;
}

.tech {
  font-size: 15px;
  font-weight: var(--font-semibold);
}

.link_dev {
  display: flex;
  justify-content: space-between;
}

.project__item {
  color: var(--title-color);
  padding: 0.25rem 0.75rem;
  font-weight: var(--font-medium);
  border-radius: 0.5rem;
  text-transform: capitalize;
}

.project__item:hover {
  background-color: var(--title-color);
  color: var(--container-color);
}

.project__container {
  grid-template-columns: repeat(2, max-content);
  gap: 3rem;
  justify-content: center;
}

.flexi {
  display: flex-box;
}

.all {
  display: flex;
  justify-content: center;
  align-items: center;
}

.all__logo {
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #222);
}

.all__logo:hover {
  -webkit-filter: drop-shadow(10px 10px 10px yellow);
  filter: drop-shadow(10px 10px 10px red);
}

.project__card {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.project__img {
  width: 295px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
}

.project__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-bold);
  margin-bottom: var(--mb-0-5);
}

.project__button {
  color: var(--text-color);
  font-size: var(--small-font-size);
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
}

.project__button-icon {
  font-size: 1rem;
  transition: 0.4s;
}

.project__button:hover .project__button-icon {
  transform: translateX(0.25rem);
}

.underline {
  text-decoration: underline;
}

.project__img:hover {
  cursor: pointer;
  border: 2px solid black;
}

/* ACTIVE PROJECT */
.active__project {
  background-color: var(--title-color);
  color: var(--container-color);
}

.black {
  background-color: black;
  color: aliceblue;
}

/* BREAK POINTS */
@media screen and (min-width: 1450px) {
  .project__container {
    grid-template-columns: repeat(3, max-content);
  }
}

@media screen and (max-width: 992px) {
  .project__container {
    gap: 1.25rem;
    grid-template-columns: max-content;
  }

  .project__card {
    padding: 1rem;
  }

  .project__img {
    margin-bottom: 0.75rem;
  }

  .project__title {
    margin-bottom: 0.25rem;
  }
}

@media screen and (max-width: 768px) {
  .project__container {
    grid-template-columns: max-content;
  }

  .all__logo {
    margin-top: 5px;
  }
}

@media screen and (max-width: 576px) {
  .project__container {
    grid-template-columns: 1fr;
  }

  .project__img {
    width: 100%;
  }

  .all__logo {
    margin-top: 5px;
  }
}

@media screen and (max-width: 350px) {
  .project__item {
    font-size: var(--small-font-size);
  }

  .project__filters {
    column-gap: 0.25rem;
  }

  .all__logo {
    margin-top: 5px;
  }
}
