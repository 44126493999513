.footer {
  background-color: var(--container-color);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
  padding: 2rem 0 0.1rem;
}

.footer__title {
  color: var(--title-color);
  text-align: center;
  margin-bottom: var(--mb-2);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
