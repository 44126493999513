.achievement__card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  column-gap: 0.75rem;
  margin-bottom: var(--mb-2);
  cursor: pointer;
  gap: 3rem;
}

.project__imgg {
  width: 400px;
  border-radius: 1rem;
  margin-bottom: var(--mb-1);
  height: 285px;
}

.container_im {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1.25rem;
  border-radius: 1rem;
}

.project__title {
  align-items: center;
  justify-content: center;
}

.project__imgg:hover {
  cursor: pointer;
  border: 2px solid black;
}

@media screen and (max-width: 550px) {
  .project__imgg {
    width: 100%;
    height: 50%;
  }
}

@media screen and (max-width: 360px) {
  .section__title {
    font-size: 2.5rem;
  }
}
