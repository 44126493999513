@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

.nav {
  height: calc(var(--header-height) + 1.5rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1rem;
  padding: 1% 0;
}

.nav__toggle {
  color: var(--title-color);
  font-weight: var(--font-medium);
}
.nav__toggle:hover {
  color: var(--title-color-dark);
}

.nav__logo {
  font-size: x-large;
  color: var(--title-color);
}

.nav__list {
  display: flex;
  font-size: xx-large;
  column-gap: 2rem;
}

.nav__link {
  font-family: Roboto Mono, monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.nav__icon,
.nav__close,
.nav__toggle {
  display: none;
}

/* ACTIVE LINK */
.nav__item .active-link {
  color: var(--title-color-dark);
}

.nav__link:hover {
  color: var(--title-color-dark);
}

.nav__item {
  cursor: pointer;
}

/* CHANGE HEADER */
.scroll-header {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
}

/* BREAK POINTS */
@media screen and (max-width: 932px) {
  .header {
    top: 0;
  }

  .nav {
    height: var(--header-height);
  }

  .nav__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    background-color: var(--body-color);
    padding: 2rem 1.5rem 4rem;
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 1.5rem 1.5rem;
    transition: 0.3s;
  }

  .show-menu {
    top: var(--header-height);
  }

  .nav__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .nav__icon {
    font-size: 1.2rem;
  }

  .nav__close {
    position: absolute;
    right: 1.3rem;
    bottom: 0.5rem;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--title-color);
  }

  .nav__close:hover {
    color: var(--title-color-dark);
  }

  .nav__toggle {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .nav__icon,
  .nav__close,
  .nav__toggle {
    display: block;
  }

  .nav__link {
    font-size: 10px;
  }
}

@media screen and (max-width: 350px) {
  .nav__menu {
    padding: 2rem 0.25rem 4rem;
  }

  .nav__list {
    column-gap: 0;
  }
}
